import React from "react";
import "./underConstruction.css";
import "./pallete.css";
// import "../Footer.css"

function UnderConstruction() {
  return (
    <div className="">
      <img className="divContainer" srcSet="img/general/underconstruction1.jpg"></img>
    </div>
      
  );
}

export default UnderConstruction;